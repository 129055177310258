import { ActionTree, ActionContext } from 'vuex'
import {
  Order,
  Customer,
  CustomerPhoneInput,
  CustomerAddressInput,
  TokenSet,
  UpdatePasswordInput,
  GiftAidDeclarationInput,
  CustomerConstituencyInput,
  CustomerAttributeInput,
  CustomerContactPreferenceInput,
  CustomerInterestInput,
  CustomerInput,
  AffiliationInput,
  AssociationInput,
  AccountInput,
  CustomerResearchInput,
  NewsletterInput,
} from '@Core/@types/skyway'
import { RootState } from '@Core/store/types'
import * as customer from '@Account/api/queries/customer.gql'
import { config } from '@Core/config/skyway'
import { State } from './state'
import { types } from './types'
import jwtDecode from 'jwt-decode'

/**
 * Method to check that the activeCustomerRecieved is the same as our stored
 * token
 */
const validateCorrectCustomer = (recieved_ref: string, storeRef: string) => {
  return recieved_ref === storeRef
}

const actions: ActionTree<State, RootState> = {
  init(context) {
    const knownUsers = this.app.$cookies.get('known_users') || []
    context.commit(types.SET_KNOWN_USERS, knownUsers)
  },

  /**
   * Social Login
   */
  async socialLogin(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const data = await this.app.$authService.socialLogin(input)
    return data.socialLogin
  },

  /**
   * Login and fetch the active customer details if successful
   */
  async login(
    context: ActionContext<State, RootState>,
    { username, password }
  ): Promise<any> {
    const data = await this.app.$authService.login({ username, password })
    return data.login
  },

  /**
   * Auto-re-login the current user - useful after applying a new membership etc
   */
  async reauthenticate(
    context: ActionContext<State, RootState>,
    order_ref = null
  ): Promise<any> {
    const data = await this.app.$authService.reauthenticate(order_ref)
    return data.reauthenticate
  },

  /**
   * Login and fetch the active customer details if successful
   */
  async idLogin(
    context: ActionContext<State, RootState>,
    { customer_ref, password }
  ): Promise<any> {
    const data = await this.app.$authService.idLogin({ customer_ref, password })
    return data.idLogin
  },

  async tokenLogin(
    context: ActionContext<State, RootState>,
    { email, token }
  ): Promise<any> {
    const data = await this.app.$authService.tokenLogin({ email, token })
    return data.tokenLogin
  },

  /**
   * Remove the refresh cookies and customer details from store
   * Will need to hit an endpoint to clear the cookies
   */
  async logout(context: ActionContext<State, RootState>) {
    await this.app.$authService.logout()

    await context.dispatch('clearSession')

    this.commit('customer/SET_LOGGED_IN', false)
    localStorage.removeItem('vuex')
    window.location.href =
      config.URLS && config.URLS.sign_in ? config.URLS.sign_in : '/sign-in/'
  },

  /**
   * If we identify that the session is erroneous but we don't want to kill the backend session
   * we can reset the client session using this method
   * @param context
   */
  clearSession(context: ActionContext<State, RootState>) {
    this.app.$eventBus.emit('clear_cookie')

    return new Promise((resolve, reject) => {
      context.commit('basket/SET_PROMO_CODE', '', { root: true })
      context.commit(types.SET_LOGGED_IN, false)
      context.commit(types.SET_TOKEN, undefined)
      context.commit(types.LOGOUT)

      resolve(true)
    })
  },

  /**
   * Request the details for the logged in user.
   *
   * If we can't get the active customer, we need to bail out in the catch block
   * and fully logout
   */
  async getActiveCustomer(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (!process.server && context.getters.isLoggedIn) {
      const data = await this.app.$account.getActiveCustomer(fetchPolicy)

      if (
        data &&
        data.getActiveCustomer &&
        data.getActiveCustomer.display_name &&
        data.getActiveCustomer.display_name.includes('Anonymous User')
      ) {
        context.dispatch('logout')
        return false
      } else if (data.getActiveCustomer) {
        context.commit(types.SET_CUSTOMER, data.getActiveCustomer)

        return data.getActiveCustomer
      } else {
        return false
      }
    } else {
      return false
    }
  },

  /**
   * Returns whether the current user can access a preview WP
   * page, based on constituency
   */
  async checkPreviewAuth(context: ActionContext<State, RootState>) {
    if (context.state.logged_in === true) {
      await context.dispatch('getActiveCustomer')
      await context.dispatch('getCustomerConstituencies')

      const constituencyTypeRefs: string[] =
        context.state.customer.constituencies.map(
          (cons) => cons.type.constituency_type_ref
        )

      const canAccessPreviews = constituencyTypeRefs.some((ref) =>
        config.PREVIEW_CONSTITUENCY_REFS.includes(ref)
      )

      return canAccessPreviews
    }
    return false
  },

  async getPrimaryHouseholdMembers(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<Customer[]> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getPrimaryHouseholdMembers,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_PRIMARY_MEMBERS, data.getPrimaryHouseholdMembers)

    return data.getPrimaryHouseholdMembers
  },

  async getCustomerChildren(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerChildren,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_CHILDREN, data.getActiveCustomer.children)

    return data.getActiveCustomer.children
  },

  async getCustomerHousehold(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerHousehold,
      fetchPolicy,
    })

    const { data } = response

    if (data.getActiveCustomer.adults) {
      context.commit(types.SET_ADULTS, data.getActiveCustomer.adults)
    }

    if (data.getActiveCustomer.children) {
      context.commit(types.SET_CHILDREN, data.getActiveCustomer.children)
    }

    return data.getActiveCustomer.children
  },

  async getCustomerByRefAndName(
    context: ActionContext<State, RootState>,
    { customer_ref, name }
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerByRefAndName,
      variables: {
        customer_ref,
        name,
      },
    })

    const { data } = response

    return data.getCustomerByRefAndName
  },

  async getCustomerContactDetails(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerContactDetails,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_CUSTOMER_CONTACT_DETAILS, data.getActiveCustomer)

    return data.getActiveCustomer
  },

  async getCustomerInterests(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerInterests,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_CUSTOMER_INTERESTS, data.getActiveCustomer)

    return data.getActiveCustomer.interests
  },

  async getCustomerAttributes(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerAttributes,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_CUSTOMER_ATTRIBUTES, data.getActiveCustomer)

    return data.getActiveCustomer.attributes
  },

  async getCustomerConstituencies(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerConstituencies,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_CUSTOMER_CONSTITUENCIES, data.getActiveCustomer)

    return data.getActiveCustomer ? data.getActiveCustomer.constituencies : null
  },

  async getCustomerBalances(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerBalances,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_CUSTOMER_BALANCES, data.getActiveCustomer)

    return data.getActiveCustomer.balances
  },

  async getCustomerMemberships(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerMemberships,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_CUSTOMER_MEMBERSHIPS, data.getActiveCustomer)
    if (data.getActiveCustomer && data.getActiveCustomer.memberships) {
      context.commit(
        types.SET_MEMBERSHIP_HISTORY,
        data.getActiveCustomer.memberships
      )
    }

    return data.getActiveCustomer && data.getActiveCustomer.memberships
  },

  async getCustomerGiftAid(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerGiftAid,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_CUSTOMER_GIFT_AID, data.getActiveCustomer)

    return data.getActiveCustomer.giftaid
  },

  async getCustomerContactPreferences(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerContactPreferences,
      fetchPolicy,
    })

    const { data } = response

    context.commit(
      types.SET_CUSTOMER_CONTACT_PREFERENCES,
      data.getActiveCustomer
    )

    return data.getActiveCustomer.preferences
  },

  async getCustomerTickets(
    context: ActionContext<State, RootState>,
    params: { fetchPolicy?: string; start_date?: string }
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    let query

    if (!params) {
      const today = this.app.$moment().utc()
      const thisMorning = today.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      params = {
        start_date: thisMorning.format(),
      }
    }

    if (params.start_date) {
      query = {
        query: customer.getCustomerTickets,
        fetchPolicy: params.fetchPolicy || 'network-only',
        variables: {
          start_date: params.start_date || '',
        },
      }
    } else {
      query = {
        query: customer.getCustomerTickets,
        fetchPolicy: params.fetchPolicy || 'network-only',
      }
    }

    const response = await client.query(query)

    const { data } = response

    context.commit(types.SET_TICKET_HISTORY, data.getActiveCustomer)

    return data.getActiveCustomer.tickets
  },

  async getSuggestedEvents(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'cache-first'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getSuggestedEvents,
      fetchPolicy,
      variables: {
        types: 'event',
      },
    })

    const { data } = response

    return data.getSuggestedEvents
  },

  async getOrderHistory(
    context: ActionContext<State, RootState>,
    params: {
      type?: 'pending' | 'history'
      per_page: number
      cursor_ref: string | null
    }
  ): Promise<Order[]> {
    const data = await this.app.$account.getOrderHistory(
      params.type,
      params.per_page,
      params.cursor_ref
    )

    if (data && data.orderHistory) {
      context.commit(types.SET_ORDER_HISTORY, data.orderHistory)
      return data.orderHistory
    }

    return []
  },

  async reprintOrder(
    context: ActionContext<State, RootState>,
    order_ref
  ): Promise<Order[]> {
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: customer.reprintOrder,
      variables: {
        order_ref,
      },
    })

    const { data } = response

    return data.reprintOrder
  },

  async getCustomerEntitlementsCoupon(
    context: ActionContext<State, RootState>
  ): Promise<Order[]> {
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: customer.getCustomerEntitlementsCoupon,
    })

    const { data } = response

    context.commit(types.SET_COUPONS, data.custom.response)

    return data.custom.response
  },

  async getOrder(
    context: ActionContext<State, RootState>,
    orderId: string
  ): Promise<Order> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.order,
      variables: {
        id: parseInt(orderId),
      },
    })

    const { data } = response
    return data.order
  },

  async getCustomerContributions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerContributions,
    })

    const { data } = response

    context.commit(
      types.SET_CONTRIBUTION_HISTORY,
      data.getActiveCustomer.contributions
    )

    context.commit(types.SET_CUSTOMER_CONTRIBUTIONS, data.getActiveCustomer)

    return data.getActiveCustomer.contributions
  },

  async getCustomerGiftSponsorships(
    context: ActionContext<State, RootState>,
    email: string
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerGiftSponsorships,
      variables: {
        email,
      },
    })

    const { data } = response

    context.commit(types.SET_GIFT_SPONSORSHIPS, data.getList.response)

    return data.getList.response
  },

  async getCustomerGiftMemberships(
    context: ActionContext<State, RootState>,
    email: string
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getCustomerGiftMemberships,
      variables: {
        email,
      },
    })

    const { data } = response

    context.commit(types.SET_GIFT_MEMBERSHIPS, data.getList.response)

    return data.getList.response
  },

  /**
   * Update the active customer's details
   *
   */
  async updateCustomer(
    context: ActionContext<State, RootState>,
    input: CustomerInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: customer.updateCustomer,
      variables: {
        customerInput: input,
      },
    })

    const { data } = response

    return data.updateCustomer
  },

  async updatePhones(
    context: ActionContext<State, RootState>,
    input: Array<CustomerPhoneInput>
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updatePhones,
      variables: {
        customerPhonesInput: input,
      },
    })

    const { data } = response

    return data.updatePhones
  },

  async updateAddresses(
    context: ActionContext<State, RootState>,
    input: Array<CustomerAddressInput>
  ): Promise<any> {
    context.commit('basket/SET_BASKET_UPDATING', true, { root: true })
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateAddresses,
      variables: {
        customerAddressesInput: input,
      },
    })

    const { data } = response

    context.commit('basket/SET_BASKET_UPDATING', false, { root: true })
    return data.updateAddresses
  },

  async updateAddress(
    context: ActionContext<State, RootState>,
    input: CustomerAddressInput
  ): Promise<any> {
    context.commit('basket/SET_BASKET_UPDATING', true, { root: true })
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateAddress,
      variables: {
        customerAddressInput: input,
      },
    })

    const { data } = response
    context.commit('basket/SET_BASKET_UPDATING', false, { root: true })
    return data.updateAddress
  },

  async updatePassword(
    context: ActionContext<State, RootState>,
    input: UpdatePasswordInput
  ): Promise<boolean> {
    const response = await this.app.$apolloNonPersisted.mutate({
      mutation: customer.updatePassword,
      variables: {
        updatePasswordInput: input,
      },
    })

    const { data } = response

    return data.updatePassword
  },

  async updateGiftAid(
    context: ActionContext<State, RootState>,
    input: [GiftAidDeclarationInput]
  ) {
    const response = await this.app.$apolloNonPersisted.mutate({
      mutation: customer.updateGiftAidDeclarations,
      variables: {
        giftAidDeclarations: input,
      },
    })

    const { data } = response

    return data.updateGiftAidDeclarations
  },

  async processLogin(
    context: ActionContext<State, RootState>,
    input: TokenSet
  ): Promise<any> {
    // delete all values from apollo cache to make sure all data is fetched fresh from server
    Object.keys(this.app.$apolloNonPersisted.cache.data.data).forEach((key) =>
      this.app.$apolloNonPersisted.cache.data.delete(key)
    )

    context.commit(types.SET_TOKEN, input.token)
    context.commit(types.SET_LOGGED_IN, true)

    const customer = await context.dispatch('getActiveCustomer', 'network-only')
    await this.dispatch('basket/getBasketProperties', {
      fetchPolicy: 'network-only',
    })

    if (customer) {
      this.app.$eventBus.emit('login')
      this.app.$eventBus.emit('update_cookie', {
        is_logged_in: true,
      })

      // Store email / name to be matched on future login attempts
      context.commit(
        types.SET_KNOWN_USERS,
        `${customer.first_name}|${customer.meta.email}`
      )

      this.app.$cookies.set(
        'known_users',
        JSON.stringify(context.state.known_users)
      )

      return customer
    } else {
      context.commit(types.SET_LOGGED_IN, false)
      context.commit(types.SET_TOKEN, undefined)
      return false
    }
  },

  async sendResetToken(
    context: ActionContext<State, RootState>,
    email
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: customer.sendResetToken,
      variables: {
        email,
      },
    })

    const { data } = response

    return data.sendResetToken
  },

  async sendMagicLoginLink(
    context: ActionContext<State, RootState>,
    email
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: customer.sendMagicLoginLink,
      variables: {
        email,
      },
    })

    const { data } = response

    return data.sendMagicLoginLink
  },

  /**
   * Create a new customer (register)
   */
  async createCustomer(
    context: ActionContext<State, RootState>,
    { input, recaptcha }
  ): Promise<any> {
    const data = await this.app.$account.createCustomer(input, recaptcha)

    return data.createCustomer
  },

  async convertToHousehold(
    context: ActionContext<State, RootState>,
    input
  ): Promise<number> {
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: customer.convertToHousehold,
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    this.dispatch('customer/processLogin', data.convertToHouseholdDefault.token)

    return data.convertToHousehold
  },

  async createHousehold(
    context: ActionContext<State, RootState>,
    input
  ): Promise<number> {
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: customer.createHousehold,
      variables: {
        createHouseholdInput: input,
      },
    })

    const { data } = response

    return data.createHousehold
  },

  async createHouseholdMember(
    context: ActionContext<State, RootState>,
    input: any
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.createHouseholdMember,
      variables: {
        customerInput: input.customerInput,
        type: input.type,
      },
    })

    const { data } = response

    return data.createHouseholdMember
  },

  async sendOrderConfirmation(
    context: ActionContext<State, RootState>,
    order_ref: OID
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.sendOrderConfirmation,
      variables: {
        order_ref,
      },
    })

    const { data } = response

    return data.sendOrderConfirmation
  },

  async sendTickets(
    context: ActionContext<State, RootState>,
    order_ref: OID
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.sendTickets,
      variables: {
        order_ref,
      },
    })

    const { data } = response

    return data.sendTickets
  },

  async updateHouseholdMember(
    context: ActionContext<State, RootState>,
    input: CustomerInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateHouseholdMember,
      variables: {
        customerInput: input,
      },
    })

    const { data } = response

    return data.updateHouseholdMember
  },

  async removeHouseholdMember(
    context: ActionContext<State, RootState>,
    customerRef: String
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.removeHouseholdMember,
      variables: {
        customerRef,
      },
    })

    const { data } = response

    return data.removeHouseholdMember
  },

  async createConstituentAndAssociation(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.createConstituentAndAssociation,
      variables: {
        customerInput: input.customerInput,
        associationInput: input.associationInput,
        addressInput: input.addressInput,
      },
    })

    const { data } = response

    return data.createConstituentAndAssociation
  },

  async updateAffiliation(
    context: ActionContext<State, RootState>,
    input: AffiliationInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateAffiliation,
      variables: {
        updateAffiliationInput: input,
      },
    })

    const { data } = response

    return data.updateAffiliations
  },

  async transferSession(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<void> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.transferSession,
      fetchPolicy,
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    if (data.transferSession.token) {
      context.commit(types.SET_TOKEN, data.transferSession.token)
    }
  },

  async updateContactPreferences(
    context: ActionContext<State, RootState>,
    input: Array<CustomerContactPreferenceInput>
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateContactPreferences,
      variables: {
        customerContactPreferencesInput: input,
      },
    })

    const { data } = response

    return data.updateContactPreferences
  },

  async updateInterests(
    context: ActionContext<State, RootState>,
    input: Array<CustomerInterestInput>
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateInterests,
      variables: {
        customerInterestsInput: input,
      },
    })

    const { data } = response

    return data.updateInterests
  },

  async updateConstituencies(
    context: ActionContext<State, RootState>,
    input: Array<CustomerConstituencyInput>
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateConstituencies,
      variables: {
        customerConstituenciesInput: input,
      },
    })

    const { data } = response

    return data.updateActiveCustomerConstituencies
  },

  async updateConstituency(
    context: ActionContext<State, RootState>,
    input: CustomerConstituencyInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateConstituency,
      variables: {
        customerConstituencyInput: input,
      },
    })

    const { data } = response

    return data.updateConstituency
  },

  async deleteConstituency(
    context: ActionContext<State, RootState>,
    constituency_ref
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.deleteConstituency,
      variables: {
        constituency_ref,
      },
    })

    const { data } = response

    return data.deleteConstituency
  },

  async updateAttributes(
    context: ActionContext<State, RootState>,
    input: Array<CustomerAttributeInput>
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateAttributes,
      variables: {
        customerAttributesInput: input,
      },
    })

    const { data } = response

    return data.updateActiveCustomerAttributes
  },

  async replaceAttributes(
    context: ActionContext<State, RootState>,
    input: Array<CustomerAttributeInput>
  ): Promise<any> {
    if (context.state.customer && context.state.customer.attributes) {
      const { attributes } = context.state.customer
      for (const att of input) {
        const existing = attributes.find(
          (a) => a.keyword_ref === att.keyword_ref
        )

        if (existing) {
          await this.dispatch(
            'customer/deleteAttribute',
            existing.attribute_ref
          )
        }
      }
    }

    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateAttributes,
      variables: {
        customerAttributesInput: input,
      },
    })

    const { data } = response

    return data.updateActiveCustomerAttributes
  },

  async updateAttribute(
    context: ActionContext<State, RootState>,
    input: CustomerAttributeInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateAttribute,
      variables: {
        customerAttributeInput: input,
      },
    })

    const { data } = response

    return data.updateAttribute
  },

  async deleteAttribute(
    context: ActionContext<State, RootState>,
    attribute_ref
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.deleteAttribute,
      variables: {
        attribute_ref,
      },
    })

    const { data } = response

    return data.deleteAttribute
  },

  async updateAssociation(
    context: ActionContext<State, RootState>,
    input: AssociationInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateAssociation,
      variables: {
        updateAssociationInput: input,
      },
    })

    const { data } = response

    return data.updateAssociation
  },

  async deleteAssociation(
    context: ActionContext<State, RootState>,
    association_ref
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.deleteAssociation,
      variables: {
        association_ref,
      },
    })

    const { data } = response

    return data.deleteAssociation
  },

  async updateResearch(
    context: ActionContext<State, RootState>,
    input: CustomerResearchInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.updateResearch,
      variables: {
        customerResearchInput: input,
      },
    })

    const { data } = response

    return data.updateResearch
  },

  async deleteResearch(
    context: ActionContext<State, RootState>,
    research_ref
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.deleteResearch,
      variables: {
        research_ref,
      },
    })

    const { data } = response

    return data.deleteResearch
  },

  async getGiftCertificateBalance(
    context: ActionContext<State, RootState>,
    code: string
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.getGiftCertificateBalance,
      variables: {
        code,
      },
    })

    const { data } = response

    return data.getGiftCertificateBalance
  },

  async addDirectDebitAccount(
    context: ActionContext<State, RootState>,
    account: AccountInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.addDirectDebitAccount,
      variables: {
        account,
      },
    })

    const { data } = response

    return data.addDirectDebitAccount
  },

  async getCountries(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const data = await this.app.$account.getCountries(fetchPolicy)

    context.commit(types.SET_COUNTRIES, data.getCountries)

    return data.getCountries
  },

  async getStates(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const data = await this.app.$account.getStates(fetchPolicy)

    context.commit(types.SET_STATES, data.getStates)

    return data.getStates
  },

  async getContactPreferenceCategories(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer.getContactPreferenceCategories,
      context: {
        public: true,
      },
      fetchPolicy,
    })

    const { data } = response

    if (data && data.getContactPreferenceCategories) {
      context.commit(
        types.SET_PREFERENCE_CATEGORIES,
        data.getContactPreferenceCategories
      )
    }

    return data.getContactPreferenceCategories
  },

  async joinLoyaltyScheme(
    context: ActionContext<State, RootState>,
    account: AccountInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.joinLoyaltyScheme,
    })

    const { data } = response

    return data.joinLoyaltyScheme
  },

  async donateLoyaltyPoints(
    context: ActionContext<State, RootState>,
    account: AccountInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.donateLoyaltyPoints,
    })

    const { data } = response

    return data.donateLoyaltyPoints
  },

  /**
   * Activate a login on to an existing constituent record
   */
  async activateAccount(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.activateAccount,
      variables: {
        input,
      },
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    return data.activateAccount
  },

  async checkCustomerHasLogin(
    context: ActionContext<State, RootState>,
    customer_ref: string
  ) {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: customer.checkCustomerHasLogin,
      variables: {
        customer_ref,
      },
    })

    const { data } = response

    return data.checkCustomerHasLogin
  },

  async checkEmailExists(
    context: ActionContext<State, RootState>,
    email: string
  ) {
    const data = await this.app.$authService.checkEmailExists(email)

    return data.checkEmailExists
  },

  async getInterestTypes(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const data = await this.$account.getInterestTypes()
    context.commit(types.SET_INTEREST_TYPES, data.getInterestTypes)
    return data.getInterestTypes
  },

  setGiftAidRequest(
    context: ActionContext<State, RootState>,
    payload: any
  ): void {
    context.commit(types.SET_REQUEST, payload)
  },

  async processGiftAidRequests(
    context: ActionContext<State, RootState>
  ): Promise<void> {
    const requests = this.getters['giftaid/getRequests']
    if (requests != null) {
      await this.dispatch('customer/updateGiftAid', requests)
      context.commit(types.CLEAR_REQUESTS)
    }
  },

  async newsletterSignup(
    context: ActionContext<State, RootState>,
    args: { newsletterInput: NewsletterInput; recaptcha: string }
  ) {
    const data = await this.$account.newsletterSignup(
      args.newsletterInput,
      args.recaptcha
    )

    return data.newsletterSignup
  },
}

export default actions
