export const Pages = {
  Index: () => import('@Account/pages/account.vue').then((m) => m.default || m),
  Account: () =>
    import('@Account/pages/account/index.vue').then((m) => m.default || m),
  Access: () =>
    import('@Account/pages/account/access').then((m) => m.default || m),
  Addresses: () =>
    import('@Account/pages/account/addresses').then((m) => m.default || m),
  Details: () =>
    import('@Account/pages/account/details').then((m) => m.default || m),
  GiftAid: () =>
    import('@Account/pages/account/gift-aid').then((m) => m.default || m),

  Membership: () =>
    import('@Account/pages/account/membership').then((m) => m.default || m),
  Orders: () =>
    import('@Account/pages/account/orders').then((m) => m.default || m),
  OrdersPending: () =>
    import('@Account/pages/account/pending').then((m) => m.default || m),
  AccountPendingOrders: () =>
    import('@Account/pages/account/pending-list').then((m) => m.default || m),
  Password: () =>
    import('@Account/pages/account/password').then((m) => m.default || m),
  Preferences: () =>
    import('@Account/pages/account/preferences').then((m) => m.default || m),
  Vouchers: () =>
    import('@Account/pages/account/vouchers').then((m) => m.default || m),
}
