// @ts-ignore:next-line
import OverviewSvg from '~/static/images/icons/qr_code.svg?inline'

export default [
  {
    label: 'Overview & bookings',
    name: '/account',
    group: 'Overview',
    showGroupLabel: false,
    icon: OverviewSvg,
    dev: false,
  },
  {
    label: 'Membership',
    name: '/account/membership',
    group: 'Memberships',
    dev: false,
  },

  {
    label: 'Personal details',
    name: '/account/details',
    group: 'Your details',
    dev: false,
  },

  {
    label: 'Address book',
    name: '/account/addresses',
    group: 'Your details',
    dev: false,
  },
  // {
  //   label: 'Order history',
  //   name: '/account/orders',
  //   group: 'History & settings',
  //   dev: false,
  // },
  // {
  //   label: 'Pending orders',
  //   name: '/account/orders/pending',
  //   group: 'History & settings',
  //   dev: false,
  // },
  {
    label: 'Preferences & interests',
    name: '/account/preferences',
    group: 'History & settings',
    dev: false,
  },
  {
    label: 'Gift Aid',
    name: '/account/gift-aid',
    group: 'History & settings',
    dev: false,
  },

  {
    label: 'Change password',
    name: '/account/password',
    group: 'History & settings',
    dev: false,
  },
]
