import Vue from 'vue'
import { MutationTree } from 'vuex'
import { config } from '@Core/config/skyway'
import { mockAddress } from '@Account/api/models/customer'
import {
  Customer,
  CustomerAddress,
  CustomerInput,
  Country,
} from '@Core/@types/skyway'
import { CustomerAddressTransformer } from '@Account/api/transformers/customer'
import { types } from './types'
import { State } from './state'

export const isDefaultAddress = (address: CustomerAddress) => {
  return (
    address.street1 === config.DEFAULT_ADDRESS.street1 &&
    address.city === config.DEFAULT_ADDRESS.city
  )
}

export const isMockAddress = (address: CustomerAddress) => {
  return (
    address.street1.toLowerCase().includes('roundhouse') &&
    !/\d/.test(address.street1)
  )
}

export const isMockPostcode = (address: CustomerAddress) => {
  return address.postal_code === mockAddress.postal_code
}

export const isValidAddress = (address: CustomerAddress) => {
  return (
    typeof address.street1 === 'string' &&
    address.street1.length > 0 &&
    typeof address.postal_code === 'string' &&
    address.postal_code.length > 0
  )
}

const mutations: MutationTree<State> = {
  [types.SET_KNOWN_USERS](state: State, knownUsers: string): void {
    state.known_users.push(knownUsers)
  },

  [types.SET_LOGGED_IN](state: State, status: boolean): void {
    state.logged_in = status
  },

  [types.SET_TOKEN](state: State, token: string): void {
    state.token = token
  },

  [types.SET_CHILDREN](state: State, payload: any): void {
    Vue.set(state.customer, 'children', payload)
  },

  [types.SET_PRIMARY_MEMBERS](state: State, payload: any): void {
    state.primary_members = payload
  },

  [types.SET_ADULTS](state: State, payload: any): void {
    state.adults = payload.filter((e) => e)
    Vue.set(
      state.customer,
      'adults',
      payload.filter((e) => e)
    )
  },

  [types.SET_CUSTOMER](state: State, payload: any): void {
    state.customer = payload
    if (payload && !payload.tickets) {
      Vue.set(state.customer, 'tickets', [])
    }
    if (payload && !payload.preferences) {
      Vue.set(state.customer, 'preferences', [])
    }
    if (payload && payload.addresses) {
      for (const index in payload.addresses) {
        if (
          !isMockAddress(payload.addresses[index]) &&
          !isDefaultAddress(payload.addresses[index]) &&
          isValidAddress(payload.addresses[index])
        ) {
          Vue.set(
            state.customer.addresses,
            index,
            CustomerAddressTransformer(payload.addresses[index])
          )
        }
      }
    }
    if (!payload.meta.phone) {
      const mostRecent =
        payload.phones &&
        payload.phones.length &&
        payload.phones[payload.phones.length - 1]
      if (mostRecent) {
        Vue.set(
          state.customer,
          'meta',
          Object.assign({}, state.customer.meta, { phone: mostRecent.number })
        )
      }
    }
  },

  [types.SET_TICKET_HISTORY](state: State, payload: any): void {
    if (payload && state.customer) {
      Vue.set(state.customer, 'tickets', payload.tickets)
    }
  },

  [types.SET_ORDER_HISTORY](state: State, orders: any): void {
    if (orders && state.customer) {
      Vue.set(state.customer, 'orders', orders)
    }
  },

  [types.SET_LATEST_ORDER_ID](state: State, orderId: any): void {
    state.latest_order_id = orderId
  },

  [types.SET_MEMBERSHIP_HISTORY](state: State, payload: any): void {
    state.membership_history = payload
  },

  [types.SET_CONTRIBUTION_HISTORY](state: State, payload: any): void {
    state.contribution_history = payload
  },

  [types.SET_GIFT_MEMBERSHIPS](state: State, payload: any): void {
    const data = []
    payload.forEach((item) => {
      const transformed = Object.keys(item).reduce((newItem, key) => {
        newItem[key.toLowerCase()] = item[key]
        return newItem
      }, {})
      data.push(transformed)
    })
    Vue.set(state.gifts, 'memberships', data)
  },

  [types.SET_GIFT_SPONSORSHIPS](state: State, payload: any): void {
    state.gifts.sponsorships = payload
  },

  [types.LOGOUT](state: State): void {
    state.logged_in = false
    state.token = undefined
    state.customer = undefined
  },

  [types.TRANSFER_SESSION_FAILED](state: State): void {
    state.customer = undefined
  },

  /**
   * Temporary email is committed when someone tries to sign in
   * to potentially be used on either registration or forgotten password
   */
  [types.SET_TEMPORARY_EMAIL](state: State, payload: string): void {
    state.temporary_email = payload
  },

  [types.CLEAR_TEMPORARY_EMAIL](state: State): void {
    state.temporary_email = null
  },

  [types.SET_CUSTOMER_CONTACT_DETAILS](state: State, payload: Customer): void {
    if (!state.customer) state.customer = {}
    if (payload && payload.addresses) {
      Vue.set(
        state.customer,
        'addresses',
        payload.addresses.filter(
          (a) => a && !isMockAddress(a) && !isDefaultAddress(a)
        )
      )
      Vue.set(state.customer, 'billing_address', payload.billing_address)
      Vue.set(state.customer, 'phones', payload.phones)
      Vue.set(state.customer, 'emails', payload.emails)
    }
  },

  [types.SET_CUSTOMER_INTERESTS](state: State, payload: Customer): void {
    if (!state.customer) state.customer = {}
    if (payload && payload.interests) {
      Vue.set(state.customer, 'interests', payload.interests)
    }
  },

  [types.SET_CUSTOMER_ATTRIBUTES](state: State, payload: Customer): void {
    if (!state.customer) state.customer = {}
    if (payload && payload.attributes) {
      Vue.set(state.customer, 'attributes', payload.attributes)
    }
  },

  [types.SET_CUSTOMER_CONSTITUENCIES](state: State, payload: Customer): void {
    if (!state.customer) state.customer = {}
    if (payload && payload.constituencies) {
      Vue.set(state.customer, 'constituencies', payload.constituencies)
    }
  },

  [types.SET_CUSTOMER_BALANCES](state: State, payload: Customer): void {
    if (!state.customer) state.customer = {}
    if (payload && payload.balances) {
      Vue.set(state.customer, 'balances', payload.balances)
    }
  },

  [types.SET_CUSTOMER_MEMBERSHIPS](state: State, payload: Customer): void {
    if (!state.customer) state.customer = {}
    if (payload && payload.memberships) {
      Vue.set(state.customer, 'memberships', payload.memberships)
    }
  },

  [types.SET_CUSTOMER_CONTRIBUTIONS](state: State, payload: Customer): void {
    if (!state.customer) state.customer = {}
    if (payload && payload.contributions) {
      Vue.set(state.customer, 'contributions', payload.contributions)
    }
  },

  [types.SET_CUSTOMER_GIFT_AID](state: State, payload: Customer): void {
    if (!state.customer) state.customer = {}
    if (payload && payload.giftaid) {
      Vue.set(state.customer, 'giftaid', payload.giftaid)
    }
  },

  [types.SET_CUSTOMER_CONTACT_PREFERENCES](
    state: State,
    payload: Customer
  ): void {
    if (!state.customer) state.customer = {}
    if (payload && payload.preferences) {
      Vue.set(state.customer, 'preferences', payload.preferences)
    }
  },

  [types.SET_COUNTRIES](state: State, payload: Array<Country>): void {
    const countries = []
    payload
      ? payload.forEach((country, i) => {
          if (country.iso_code === 'USA') {
            countries.splice(i, 1)
            countries.unshift(country)
          }
          if (country.name === '') {
            countries.splice(i, 1)
          }
        })
      : null
    state.countries = countries
  },

  [types.SET_STATES](state: State, payload: Array<any>): void {
    state.states = payload
  },

  /**
   * By using Vue.set and Object.assign to map to existing store values
   * we keep everything in sync and reactive, while allowing us to
   * pass the entire updated object back to the store
   */
  [types.UPDATE_CUSTOMER](state: State, payload: Customer): void {
    Vue.set(state, 'customer', Object.assign({}, state.customer, payload))
  },

  [types.UPDATE_ADULT](
    state: State,
    payload: {
      data: { key: string; value: any }
      customer: CustomerInput
    }
  ): void {
    const index = state.customer.adults.findIndex(
      (cus) => cus.customer_ref == payload.customer.customer_ref
    )

    if (index !== -1) {
      Vue.set(
        state.customer.adults,
        index,
        Object.assign(state.customer.adults[index], payload.data)
      )
      Vue.set(
        state.adults,
        index,
        Object.assign(state.adults[index], payload.data)
      )
    }
  },

  [types.ADD_ADDRESS](state: State, payload: CustomerAddressInput): void {
    const index = state.customer.addresses.length
    Vue.set(state.customer.addresses, index, payload)
  },

  [types.DELETE_ADDRESS](state: State, payload: string): void {
    const data = state.customer.addresses.filter(
      (add) => add && add.address_ref != payload
    )

    if (data) {
      Vue.set(state.customer, 'addresses', data)
    }
  },

  [types.UPDATE_ADDRESS](
    state: State,
    payload: {
      data: { key: string; value: any }
      address: CustomerAddressInput
    }
  ): void {
    const index = state.customer.addresses.findIndex(
      (add) =>
        parseInt(add.address_ref) === parseInt(payload.address.address_ref)
    )

    if (index !== -1) {
      Vue.set(
        state.customer.addresses,
        index,
        Object.assign({}, state.customer.addresses[index], payload.data)
      )
    }
  },

  [types.SET_COUPONS](state: State, payload: Object) {
    state.coupons = payload.data
  },

  [types.SET_INTEREST_TYPES](state: State, payload: Venue[]): void {
    state.interest_types = payload
  },

  [types.SET_GIFT_AID_REQUEST](state: State, payload: GiftAidRequest) {
    if (state.gift_aid_requests !== undefined) {
      const current = state.gift_aid_requests.findIndex(
        (r) => r.type_ref === parseInt(payload.type_ref)
      )
      if (current !== -1) {
        state.gift_aid_requests[current] = payload
      } else {
        state.gift_aid_requests.push(payload)
      }
    } else {
      state.gift_aid_requests = [payload]
    }
  },
  [types.CLEAR_GIFT_AID_REQUESTS](state: State) {
    state.gift_aid_requests = undefined
  },
  [types.SET_PREFERENCE_CATEGORIES](state: State, payload) {
    state.preference_categories = payload
  },
  [types.SET_SIGNED_UP](state: State, payload: boolean) {
    state.signed_up = payload
  },
}

export default mutations
