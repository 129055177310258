import {
  Customer,
  CustomerChild,
  MembershipInstance,
  CustomerContribution,
  CustomerContactPreferenceInput,
  ContactPreferenceCategory,
  InterestType,
} from '@Core/@types/skyway'
import { GiftAidRequest } from './types'

export interface State {
  logged_in?: boolean
  token?: string
  known_users: Array<string>
  customer?: any
  adults?: Array<Customer>
  children?: Array<CustomerChild>
  primary_members?: Array<Customer>
  temporary_email?: string
  preferences?: Array<CustomerContactPreferenceInput> | undefined
  preference_categories?: ContactPreferenceCategory[]
  membership_history?: Array<MembershipInstance>
  coupons?: Array<string>
  contribution_history?: Array<CustomerContribution>
  latest_order_id: number
  gifts?: object
  states?: Array<any>
  countries?: Array<any>
  interest_types?: Array<InterestType>
  gift_aid_requests?: Array<GiftAidRequest>
  funds?: Array<string>
  signed_up: boolean
}

/**
 *
 */
const state = (): State => ({
  logged_in: false,
  token: undefined,
  known_users: [],
  customer: undefined,
  adults: undefined,
  children: undefined,
  primary_members: undefined,
  temporary_email: undefined,
  membership_history: [],
  preferences: undefined,
  preference_categories: undefined,
  coupons: [],
  contribution_history: [],
  latest_order_id: 0,
  gifts: {
    memberships: [],
    sponsorships: [],
  },
  states: [],
  countries: [],
  interest_types: [],
  gift_aid_requests: undefined,
  funds: [],
  signed_up: false,
})

export default state
