import { CustomerAddress } from '@Core/@types/skyway'
import { config } from '@Core/config/skyway'

export const address: CustomerAddress = {
  address_ref: null,
  type_ref: null,
  street1: '',
  street2: '',
  city: '',
  state: '',
  country: '',
  country_ref: '',
  postal_code: '',
}

export const mockAddress: CustomerAddress = {
  ...address,
  ...config.MOCK_ADDRESS,
}
