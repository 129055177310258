import { MutationTree } from 'vuex'
import findKey from 'lodash/findKey'
import { CreateCustomerServiceIssueInput } from '@Core/@types/skyway'
import { types } from './types'
import { State } from './state'

const mutations: MutationTree<State> = {
  /**
   * Push a CSI onto the stack to be processed later
   */
  [types.SET_CSI](
    state: State,
    payload: CreateCustomerServiceIssueInput
  ): void {
    state.csis.push(payload)
    state.csis = state.csis.filter((c) => c)
  },

  [types.CLEAR_CSI](
    state: State,
    payload: CreateCustomerServiceIssueInput
  ): void {
    const exists = findKey(state.csis, (c: CreateCustomerServiceIssueInput) => {
      if (c.activity_type_ref === payload.activity_type_ref) {
        if (!c.instance_ref) {
          return true
        } else if (c.instance_ref == payload.instance_ref) {
          return true
        }
      }
      return false
    })

    if (exists && state.csis[exists]) {
      state.csis = [
        ...state.csis.slice(0, exists),
        ...state.csis.slice(exists + 1),
      ]
    }
  },

  [types.CLEAR_ALL](state: State): void {
    state.csis.splice(0, state.csis.length)
  },
}

export default mutations
