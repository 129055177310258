import { CreateCustomerServiceIssueInput } from '@Core/@types/skyway'

const DEFAULT_DATA = {
  note: '',
  activity_type_ref: '',
  activity_category_ref: '',
  contact_type_ref: '',
}

/**
 * Ensure a string representation of a number
 * is valid
 */
const isNumeric = (toCheck: string) => {
  if (typeof toCheck !== 'string') {
    return false
  }
  // @ts-ignore
  return !isNaN(toCheck) && !isNaN(parseFloat(toCheck))
}

/**
 * A simple interface to abstract complexity
 * away from the UI when dealing with CSIs
 */
class CsiHandler {
  protected store

  protected data: CreateCustomerServiceIssueInput = DEFAULT_DATA

  constructor(store) {
    this.store = store
  }

  public createCsi(input: CreateCustomerServiceIssueInput) {
    this.data = input
  }

  public setText(text: string) {
    this.data.note = text
  }

  public addLine(text: string) {
    if (this.data.note === '') {
      this.data.note = text
    } else {
      this.data.note += '\n' + text
    }
  }

  /**
   * Send a CSI to Tessitura immediately
   *
   * @return Promise<boolean>
   */
  public async sendCsi() {
    try {
      this.validate()

      const result = await this.store.dispatch(
        'customerServiceIssues/processCsi',
        this.data
      )

      this.reset()
      return result
    } catch (err_) {
      console.log(err_)
      return false
    }
  }

  /**
   * Store a CSI to be processed later
   *
   * @return Promise<boolean>
   */
  public async deferCsi() {
    try {
      this.validate()

      const result = await this.store.dispatch(
        'customerServiceIssues/setCsi',
        this.data
      )
      this.reset()
      return result
    } catch (err_) {
      console.log(err_)
    }
  }

  /**
   * Reset to default state
   *
   * @return void
   */
  protected reset() {
    Object.assign(this.data, DEFAULT_DATA)
  }

  /**
   * Is the CSI valid?
   */
  protected validate() {
    if (
      !isNumeric(this.data.activity_category_ref) ||
      !isNumeric(this.data.contact_type_ref) ||
      !isNumeric(this.data.activity_type_ref)
    ) {
      throw new Error('CSI does not contain the required parameters')
    }
  }
}

export default CsiHandler
