import { CreateCustomerServiceIssueInput } from '@Core/@types/skyway'

export interface State {
  csis: Array<CreateCustomerServiceIssueInput>
}

export const state = (): State => ({
  csis: [],
})

export default state
