export const removeEmpty = (obj: any): any => {
  Object.keys(obj).forEach((k) => {
    if (obj[k] && typeof obj[k] === 'object') removeEmpty(obj[k])
    if (obj[k] === undefined || obj[k] === null || obj[k] === '') delete obj[k]
  })
  Object.keys(obj).forEach((k) => {
    if (obj[k] && typeof obj[k] === 'object' && !Object.keys(obj[k]).length) delete obj[k]
  })
  return obj
}
