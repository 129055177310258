import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import {
  CreateCustomerServiceActionInput,
  CreateCustomerServiceIssueInput,
  UpdateCustomerServiceIssueInput,
} from '@Core/@types/skyway'
import * as csi from '@Account/api/queries/csi.gql'
import { State } from './state'
import { types } from './types'

const CSI_EXPIRY = 60

type CreateCustomerServiceIssueInputWithDate =
  CreateCustomerServiceIssueInput & { created_at?: string }

/**
 * Check if a CSI in local store is older than x
 * then we can bin it
 */
const shouldBeSent = (
  input: CreateCustomerServiceIssueInputWithDate,
  moment
): boolean => {
  if (!input.activity_type_ref) {
    return false
  }
  if (input.created_at) {
    if (
      moment(input.created_at).isBefore(
        moment().subtract(CSI_EXPIRY, 'minutes')
      )
    ) {
      console.log('[CSI NOT SENT]', {
        timestamp: input.created_at,
        csi: input,
      })
      return false
    }
  }
  return true
}

const actions: ActionTree<State, RootState> = {
  async processCsi(
    context: ActionContext<State, RootState>,
    createCustomerServiceIssueInput: CreateCustomerServiceIssueInputWithDate
  ): Promise<any> {
    if (createCustomerServiceIssueInput.activity_type_ref) {
      if (!shouldBeSent(createCustomerServiceIssueInput, this.app.$moment)) {
        context.commit(types.CLEAR_CSI, createCustomerServiceIssueInput)
        return false
      } else {
        console.warn('[CSI WAS SENT]', {
          timestamp: createCustomerServiceIssueInput.created_at,
          createCustomerServiceIssueInput,
        })
        delete createCustomerServiceIssueInput.created_at

        const response = await this.app.$apollo.mutate({
          mutation: csi.createCustomerServiceIssue,
          variables: {
            createCustomerServiceIssueInput,
          },
        })
        // remove CSI from store once it has been processed
        context.commit(types.CLEAR_CSI, createCustomerServiceIssueInput)

        const { data } = response

        return data.createCustomerServiceIssue
      }
    }

    return false
  },

  async updateCsi(
    context: ActionContext<State, RootState>,
    updateCustomerServiceIssueInput: UpdateCustomerServiceIssueInput
  ): Promise<any> {
    const response = await this.app.$apollo.mutate({
      mutation: csi.updateCustomerServiceIssue,
      variables: {
        updateCustomerServiceIssueInput,
      },
    })

    const { data } = response

    return data.updateCustomerServiceIssue
  },

  async createCsiAction(
    context: ActionContext<State, RootState>,
    createCustomerServiceActionInput: CreateCustomerServiceActionInput
  ): Promise<any> {
    const response = await this.app.$apollo.mutate({
      mutation: csi.createCustomerServiceAction,
      variables: {
        createCustomerServiceActionInput,
      },
    })

    const { data } = response

    return data.createCustomerServiceAction
  },

  async deleteCsiByActivityType(
    context: ActionContext<State, RootState>,
    { activity_type_ref, customer_ref }
  ): Promise<any> {
    const response = await this.app.$apollo.mutate({
      mutation: csi.deleteCustomerServiceIssueByActivityType,
      variables: {
        activity_type_ref,
        customer_ref,
      },
    })

    const { data } = response

    return data.deleteCustomerServiceIssueByActivityType
  },

  async getActivityTypes(
    context: ActionContext<State, RootState>
  ): Promise<[]> {
    const response = await this.app.$apollo.query({
      query: csi.getActivityTypes,
    })

    const { data } = response

    // this query is actually a call to the getReferenceData endpoint,
    // so this looks wrong, but it isn't!
    return data.getReferenceData && data.getReferenceData
      ? data.getReferenceData.response
      : []
  },

  async setCsi(
    context: ActionContext<State, RootState>,
    input: CreateCustomerServiceIssueInput
  ) {
    return await new Promise((resolve) => {
      const created_at = this.app.$moment().format()

      const data = { ...input, created_at }

      console.log(
        '[CSI STORED TO BE PROCESSED AT CONFIRMATION]',
        { timestamp: created_at },
        { data }
      )

      context.commit(types.SET_CSI, data)

      resolve(true)
    })
  },
}

export default actions
