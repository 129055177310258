















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Customer } from '@Core/@types/skyway'
//import ButtonDropdown from '@UI/components/navigation/ButtonDropdown.vue'

interface ITab {
  name: string
  label: string
}

@Component({})
export default class AccountHeader extends Vue {
  /** logged in customer object */
  @Prop({ type: Object }) customer?: Customer
  /** Array of tabs to display on mobile */
  // @Prop({ type: Array, required: true }) tabs!: ITab[]
  /** Selected Tab */
  // @Prop({ type: Object, required: true }) selected!: ITab

  async logout() {
    await this.$store.dispatch('customer/logout')
    // this.$router.push(this.$config.get('URLS').sign_in)
  }
}
