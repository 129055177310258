









































import { Component, Vue, namespace } from 'nuxt-property-decorator'
import { findIndex } from 'lodash'
import HeaderPart from '@Core/components/parts/header/HeaderPart.vue'
import AccountHeader from '@Account/layouts/header.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'
import VerticalNavigation from '@UI/components/navigation/VerticalNavigation.vue'
import FooterPart from '@Core/components/parts/footer/FooterPart.vue'
import ResizeObserver from '@UI/components/resize-oberserver/ResizeObserver.vue'
import AccountMenu from '@Account/helpers/account-menu'
import { Customer } from '@Core/@types/skyway'

const customer = namespace('customer')

@Component({
  components: {
    HeaderPart,
    AccountHeader,
    VerticalNavigation,
    FooterPart,
    AlertNotification,
    ResizeObserver,
  },
  middleware: ['auth'],
})
export default class AccountLayout extends Vue {
  @customer.Getter
  isLoggedIn!: boolean

  @customer.State
  customer!: Customer

  @customer.Getter
  hasExpiringMemberships

  @customer.Getter
  billingAddress

  public items = AccountMenu

  protected increment = 0

  get expiringMemberships() {
    return this.hasExpiringMemberships(this.$moment)
  }

  onClickReview(val, notificationMessages) {
    this.$router.push(notificationMessages[val].redirectUrl)
  }

  onDismissItem(val) {
    this.notificationMessages.pop(this.notificationMessages[val])
  }

  get loyaltySchemeEnabled() {
    return this.$config.get('LOYALTY_SCHEME').enabled || false
  }

  async logout() {
    await this.$store.dispatch('customer/logout')
    this.$router.push(this.$config.get('URLS').sign_in)
  }

  updateHouseholdOptions() {
    let index = findIndex(this.items, (i) => i.name === '/account/gift-aid')
    if (this.customer && this.customer.type !== 'Household') {
      this.items[index].hidden = false
    }

    index = findIndex(this.items, (i) => i.name === '/account/preferences')
    if (this.customer && this.customer.type !== 'Household') {
      this.items[index].hidden = false
    }
  }

  mounted() {
    this.updateHouseholdOptions()
  }
}
