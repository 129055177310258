export const types = {
  SET_KNOWN_USERS: 'SET_KNOWN_USERS',
  SET_LOGGED_IN: 'SET_LOGGED_IN',
  SET_TOKEN: 'SET_TOKEN',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_ADULTS: 'SET_ADULTS',
  SET_CHILDREN: 'SET_CHILDREN',
  SET_PRIMARY_MEMBERS: 'SET_PRIMARY_MEMBERS',
  SET_TICKET_HISTORY: 'SET_TICKET_HISTORY',
  SET_ORDER_HISTORY: 'SET_ORDER_HISTORY',
  SET_LATEST_ORDER_ID: 'SET_LATEST_ORDER_ID',
  SET_MEMBERSHIP_HISTORY: 'SET_MEMBERSHIP_HISTORY',
  SET_CONTRIBUTION_HISTORY: 'SET_CONTRIBUTION_HISTORY',
  SET_GIFT_MEMBERSHIPS: 'SET_GIFT_MEMBERSHIPS',
  SET_GIFT_SPONSORSHIPS: 'SET_GIFT_SPONSORSHIPS',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_STATES: 'SET_STATES',
  TRANSFER_SESSION_FAILED: 'TRANSFER_SESSION_FAILED',
  LOGOUT: 'LOGOUT',
  SET_TEMPORARY_EMAIL: 'SET_TEMPORARY_EMAIL',
  CLEAR_TEMPORARY_EMAIL: 'CLEAR_TEMPORARY_EMAIL',
  SET_CUSTOMER_CONTACT_DETAILS: 'SET_CUSTOMER_CONTACT_DETAILS',
  SET_CUSTOMER_INTERESTS: 'SET_CUSTOMER_INTERESTS',
  SET_CUSTOMER_ATTRIBUTES: 'SET_CUSTOMER_ATTRIBUTES',
  SET_CUSTOMER_CONSTITUENCIES: 'SET_CUSTOMER_CONSTITUENCIES',
  SET_CUSTOMER_BALANCES: 'SET_CUSTOMER_BALANCES',
  SET_CUSTOMER_MEMBERSHIPS: 'SET_CUSTOMER_MEMBERSHIPS',
  SET_CUSTOMER_CONTRIBUTIONS: 'SET_CUSTOMER_CONTRIBUTIONS',
  SET_CUSTOMER_GIFT_AID: 'SET_CUSTOMER_GIFT_AID',
  SET_CUSTOMER_CONTACT_PREFERENCES: 'SET_CUSTOMER_CONTACT_PREFERENCES',
  ADD_ADDRESS: 'ADD_ADDRESS',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  DELETE_ADDRESS: 'DELETE_ADDRESS',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_ADULT: 'UPDATE_ADULT',
  SET_COUPONS: 'SET_COUPONS',
  SET_INTEREST_TYPES: 'SET_INTEREST_TYPES',
  SET_GIFT_AID_REQUEST: 'SET_GIFT_AID_REQUEST',
  CLEAR_GIFT_AID_REQUESTS: 'CLEAR_GIFT_AID_REQUESTS',
  SET_PREFERENCE_CATEGORIES: 'SET_PREFERENCE_CATEGORIES',
  SET_SIGNED_UP: 'SET_SIGNED_UP',
}

export interface GiftAidRequest {
  eligible?: boolean
  type_ref?: number
}
