import { Pages } from '@Account/config/imports'

export const ROUTE_NAME = {
  INDEX: 'index',
  ACCOUNT: 'account',
  ACCESS: 'account-access',
  ADDRESSES: 'account-addresses',
  DETAILS: 'account-details',
  GIFT_AID: 'account-gift-aid',
  MEMBERSHIP: 'account-membership',
  MEMBERSHIP_BY_TYPE: 'account-membership-by-type',
  ORDERS: 'account-orders',
  ORDERS_PENDING_LIST: 'account-orders-pending-list',
  ORDERS_PENDING_ID: 'account-orders-pending-id',
  OLD_ORDERS_PENDING_ID: 'old-account-orders-pending-id',
  PASSWORD: 'account-password',
  PREFERENCES: 'account-preferences',
  VOUCHERS: 'account-vouchers',
}

export default [
  {
    path: '/account',
    component: Pages.Index,
    name: ROUTE_NAME.INDEX,
    children: [
      {
        path: '/account',
        component: Pages.Account,
        name: ROUTE_NAME.ACCOUNT,
      },
      {
        path: '/account/access',
        component: Pages.Access,
        name: ROUTE_NAME.ACCESS,
      },
      {
        path: '/account/addresses',
        component: Pages.Addresses,
        name: ROUTE_NAME.ADDRESSES,
      },
      {
        path: '/account/details',
        component: Pages.Details,
        name: ROUTE_NAME.DETAILS,
      },
      {
        path: '/account/gift-aid',
        component: Pages.GiftAid,
        name: ROUTE_NAME.GIFT_AID,
      },
      {
        path: '/account/membership',
        component: Pages.Membership,
        name: ROUTE_NAME.MEMBERSHIP,
      },
      {
        path: '/account/membership/:type?',
        component: Pages.Membership,
        name: ROUTE_NAME.MEMBERSHIP_BY_TYPE,
      },
      // {
      //   path: '/account/orders',
      //   component: Pages.Orders,
      //   name: ROUTE_NAME.ORDERS,
      // },
      // {
      //   path: '/account/orders/pending',
      //   component: Pages.AccountPendingOrders,
      //   name: ROUTE_NAME.ORDERS_PENDING_LIST,
      // },
      {
        path: '/account/orders/pending/:id',
        component: Pages.OrdersPending,
        name: ROUTE_NAME.ORDERS_PENDING_ID,
      },
      {
        path: '/secure/account/pendingorders/:id',
        component: Pages.OrdersPending,
        name: ROUTE_NAME.OLD_ORDERS_PENDING_ID,
      },
      {
        path: '/account/password',
        component: Pages.Password,
        name: ROUTE_NAME.PASSWORD,
      },
      {
        path: '/account/preferences',
        component: Pages.Preferences,
        name: ROUTE_NAME.PREFERENCES,
      },
      {
        path: '/account/vouchers',
        component: Pages.Vouchers,
        name: ROUTE_NAME.VISIT,
      },
    ],
  },
]
