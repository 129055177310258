import { GetterTree } from 'vuex'
import { config } from '@Core/config/skyway'
import {
  Customer,
  MembershipInstance,
  Order,
  CustomerAddress,
  CustomerContribution,
  InterestType,
  GiftAidDeclaration,
  CustomerConstituency,
  CustomerContactPreferenceInput,
} from '@Core/@types/skyway'
import { State } from './state'
import { RootState } from '@Core/store/types'
import { GiftAidRequest } from './types'

export enum AccessLevel {
  Advanced = 'advanced',
  Priority = 'priority',
  None = 'none',
}

const HIGHER_LEVEL_MEMBERSHIPS = ['champion', 'icon', 'legend']

export const getters: GetterTree<State, RootState> = {
  customer: (state: State): Customer | undefined => {
    return state.customer
  },

  memberships: (state: State): MembershipInstance[] => {
    return state.customer && state.customer.memberships
      ? state.customer.memberships
      : []
  },

  contributions: (state: State): CustomerContribution[] => {
    return state.customer ? state.customer.contributions : []
  },

  token: (state: State): string | undefined => {
    return state.token
  },

  isLoggedIn: (state: State): boolean => {
    return state.logged_in === true
  },

  isAcccesMember: (state: State): boolean => {
    return !!(
      state.customer &&
      state.customer.constituencies &&
      state.customer.constituencies.find(
        (c) => parseInt(c.type.constituency_type_ref) === 62
      )
    )
  },

  hasPriorityWaitingListAccess: (state: State) => {
    const memberships: MembershipInstance[] =
      state.customer &&
      state.customer.memberships?.filter((m) => m && m.current)
    if (memberships) {
      for (const m of memberships) {
        if (
          m.membership_type &&
          ['discovery', ...HIGHER_LEVEL_MEMBERSHIPS].includes(
            String(m.membership_type.title?.toLowerCase())
          )
        ) {
          return true
        }
      }
    }
    return false
  },

  priorityBookingLevel: (state: State): AccessLevel => {
    const memberships: MembershipInstance[] =
      state.customer &&
      state.customer.memberships?.filter((m) => m && m.current)

    if (memberships) {
      for (const m of memberships) {
        if (
          m.membership_type &&
          HIGHER_LEVEL_MEMBERSHIPS.includes(
            String(m.membership_type.title?.toLowerCase())
          )
        ) {
          return AccessLevel.Advanced
        } else if (
          m.membership_type &&
          (m.membership_type.title?.toLowerCase() === 'intro' ||
            m.membership_type.title?.toLowerCase() === 'discovery')
        ) {
          return AccessLevel.Priority
        }
      }
    }

    return AccessLevel.None
  },

  displayName: (state: State): string | undefined | null => {
    if (state.customer) {
      return state.customer.first_name
        ? state.customer.first_name
        : state.customer.display_name
    } else {
      return ''
    }
  },

  updatedAt: (state: State): string | undefined => {
    return state.customer ? state.customer.updated_at : undefined
  },

  isMember: (state: State): boolean => {
    if (state.customer && state.customer.memberships) {
      const ignore = config.IGNORE_MEMBERSHIPS
      const filteredMemberships = state.customer.memberships.filter(
        (m) => !ignore.includes(parseInt(m.membership_type.level_ref))
      )
      return filteredMemberships.length > 0
    } else {
      return false
    }
  },

  contribution_history: (state: State): CustomerContribution[] => {
    return state.contribution_history ? state.contribution_history : []
  },

  orders: (state: State): Order[] => {
    return state.customer && state.customer.orders ? state.customer.orders : []
  },

  tickets: (state: State): [] => {
    const blacklist = config.ORDER_HISTORY_BLACKLIST
    const tickets =
      state.customer && state.customer.tickets ? state.customer.tickets : []

    const onlyTickets = tickets.filter(
      (e) =>
        !e.title.includes('Programme') &&
        !blacklist.includes(Number(e.instance_ref))
    )

    onlyTickets.sort((a, b) => {
      if (a.date > b.date) return 1
      if (a.date < b.date) return -1
      return 0
    })

    return onlyTickets
  },

  addresses: (state: State): CustomerAddress[] => {
    return state.customer && state.customer.addresses
      ? state.customer.addresses
      : []
  },

  billingAddress: (state: State): CustomerAddress => {
    return state.customer &&
      state.customer.addresses &&
      state.customer.addresses.find((address) => address.primary)
      ? state.customer.addresses.find((address) => address.primary)
      : state.customer && state.customer.addresses
      ? state.customer.addresses[0]
      : null
  },

  currentMembership: (state: State): MembershipInstance | undefined => {
    if (state.customer && state.customer.memberships) {
      const current = state.customer.memberships.filter(
        (membership) => membership.current
      )

      if (current.length > 0) {
        return current[0]
      }
    }

    return undefined
  },

  membershipsByOrganisation:
    (state: State) =>
    (organisation: string): MembershipInstance[] => {
      return state.membership_history && state.membership_history.length
        ? state.membership_history.filter(
            (membership) =>
              membership.membership_type &&
              membership.membership_type.membership_organisation &&
              membership.membership_type.membership_organisation.includes(
                organisation
              )
          )
        : []
    },

  hasExpiringMemberships: (state: State) => (moment) => {
    if (state.membership_history && state.membership_history) {
      return state.membership_history.reduce(
        (acc: string[], membership: MembershipInstance) => {
          const a = moment(membership.dates?.renewal)
          const b = moment()

          if (a.diff(b, 'days') <= 0) {
            if (
              membership &&
              membership.membership_type &&
              membership.membership_type.membership_organisation
            ) {
              acc.push(membership.membership_type.membership_organisation)
            }
          }

          return acc
        },
        []
      )
    }
    return []
  },

  interestTypesByCategory:
    (state: State) =>
    (category_ref: string): InterestType[] => {
      return state && state.interest_types
        ? state.interest_types.filter(
            (type) => parseInt(type.category_ref) === parseInt(category_ref)
          )
        : []
    },

  isLoyaltySchemeMember: (state: State): boolean => {
    return (
      config.LOYALTY_SCHEME &&
      config.LOYALTY_SCHEME.constituency &&
      state.customer &&
      state.customer.constituencies &&
      state.customer.constituencies.find(
        (c) =>
          c.type.constituency_type_ref === config.LOYALTY_SCHEME.constituency
      )
    )
  },

  loyaltyPoints: (state: State): number => {
    return state.customer && state.customer.loyalty_points
      ? state.customer.loyalty_points
      : 0
  },

  loyaltyPointsValue: (state: State): number => {
    return state.customer && state.customer.loyalty_points_value
      ? state.customer.loyalty_points_value
      : 0
  },

  giftAid: (state: State): GiftAidDeclaration[] => {
    return state.customer && state.customer.giftaid
      ? state.customer.giftaid
      : []
  },

  getGiftAidRequests: (state: State): Array<GiftAidRequest> | null => {
    if (state.gift_aid_requests) {
      return state.gift_aid_requests
    }

    return null
  },

  contactPreferenceTypes: (state: State) => {
    if (state.preference_categories) {
      return state.preference_categories.flatMap((cat) => cat.types)
    }
    return []
  },

  customerPreferences: (
    state: State
  ): CustomerContactPreferenceInput[] | undefined => {
    return state.customer && state.customer.preferences
      ? state.customer.preferences
      : undefined
  },

  membershipsByType: (state: State) => (type: string) => {
    const { memberships } = state.customer || false

    if (memberships && memberships.length > 0) {
      return memberships.filter(
        (m) =>
          m &&
          m.membership_type &&
          m.membership_type.title.toLowerCase().includes(type.toLowerCase())
      )
    }

    return []
  },

  consituencyByType:
    (state: State) =>
    (type: string): CustomerConstituency | false => {
      const { constituencies } = state.customer || false

      if (constituencies && constituencies.length > 0) {
        return constituencies.find(
          (c) =>
            c &&
            c.type &&
            c.type.constituency_type.toLowerCase().includes(type.toLowerCase())
        )
      }

      return false
    },

  funds: (state: State) => {
    if (state.funds) {
      return state.funds
    }
    return []
  },
}

export default getters
