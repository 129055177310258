import {
  CustomerAddress,
  Customer,
  CustomerChild,
  CreateCustomerInput,
  CreateHouseholdInput,
  ContactPreferenceType,
  CustomerInput,
} from '@Core/@types/skyway'
import { removeEmpty } from '@Account/api/transformers/helpers/helpers'
import { mockAddress } from '@Account/api/models/customer'
import { config } from '@Core/config/skyway'

export function CustomerAddressTransformer(
  address
): CustomerAddress | undefined {
  if (address) {
    if (!address.postal_code || address.postal_code == '') {
      address = Object.assign({}, address, {
        postal_code: mockAddress.postal_code,
      })
    }
    return removeEmpty({
      type_ref: address.type_ref ? address.type_ref.toString() : null,
      street1: address.street1,
      street2: address.street2,
      street3: address.street3,
      city: address.city,
      state: address.state ? address.state : address.state_ref,
      postal_code: address.postal_code,
      country: address.country,
      country_ref: address.country_ref,
      address_ref: address.address_ref,
      primary: address.primary,
    })
  } else {
    return undefined
  }
}

export function CustomerTransformer(customer): Customer {
  return customer
    ? removeEmpty(
        Object.assign({
          customer_ref: customer.customer_ref || undefined,
          type_ref: customer.type_ref
            ? customer.type_ref.toString()
            : undefined,
          prefix: customer.prefix || undefined,
          prefix_ref: customer.prefix_ref || undefined,
          suffix: customer.suffix || undefined,
          suffix_ref: customer.suffix_ref || undefined,
          first_name: customer.first_name || undefined,
          middle_name: customer.middle_name || undefined,
          last_name: customer.last_name || undefined,
          primary: customer.primary || undefined,
          date_of_birth: customer.date_of_birth || undefined,
          meta: customer.meta
            ? {
                email: customer.meta.email || undefined,
                phone: customer.meta.phone || undefined,
                phone_ref: customer.meta.phone_ref || undefined,
                address: customer.meta.address
                  ? {
                      address_ref:
                        customer.meta.address.address_ref || undefined,
                      street1: customer.meta.address.street1 || undefined,
                      state: customer.meta.address.state || undefined,
                      postal_code:
                        customer.meta.address.postal_code || undefined,
                      city: customer.meta.address.city || undefined,
                      country: customer.meta.address.country || undefined,
                    }
                  : null,
              }
            : null,
        })
      )
    : null
}

export function CustomerChildTransformer(child): CustomerChild {
  return Object.assign(
    {},
    {
      customer_ref: child.customer_ref,
      first_name: child.first_name,
      last_name: child.last_name,
      date_of_birth: child.date_of_birth,
    }
  )
}

export function AdultTransformer(adult): Customer {
  return removeEmpty({
    customer_ref: adult.customer_ref,
    first_name: adult.first_name,
    last_name: adult.last_name,
    meta: {
      phone: adult.meta.phone,
      email: adult.meta.email,
    },
  })
}

export function CreateHouseholdTransformer(customer): CreateHouseholdInput {
  customer = FixAddressRecords(customer)
  return removeEmpty({
    address: CustomerAddressTransformer(customer.address),
    adults: [AdultTransformer(customer)],
  })
}

export function PreferencesTransformer(preferences): ContactPreferenceType[] {
  if (preferences) {
    return preferences.map((p) => {
      return {
        type_ref: p,
      }
    })
  } else {
    return []
  }
}

export function CreateCustomerTransformer(customer): CreateCustomerInput {
  return removeEmpty({
    customer: {
      type_ref: config.REGISTRATION_TYPE_REF,
      first_name: customer.first_name,
      last_name: customer.last_name,
      allow_marketing: customer.preferences && customer.preferences.length > 0,
      meta: {
        email: customer.primary_email,
        phone: customer.phone_number,
        address: CustomerAddressTransformer({
          ...customer.address,
          primary: true,
        }),
      },
    },
    preferences: PreferencesTransformer(customer.preferences),
    login: {
      username: customer.primary_email,
      password: customer.password,
      password_confirm: customer.password_confirm,
    },
  })
}

/**
 * Creates a random email in the format string+timestamp@domain
 *
 * Used in RH's back office system to deal with multiple web logins
 * created during the 'buy for someone else' journey
 */
export function createRandomEmail(email: string) {
  const parts = email && email.replaceAll('+', '').split('@')
  let result = ''

  if (parts && parts.length === 2) {
    const stamp = `+${Math.floor(Date.now() / 1000)}`
    result = `${parts[0]}${stamp}@${parts[1]}`
  }

  return result
}

export function CreateConstituentTransformer(constituent): CustomerInput {
  return removeEmpty({
    type_ref: constituent.type_ref || config.REGISTRATION_TYPE_REF,
    prefix_ref: '-1',
    suffix_ref: '-1',
    first_name: constituent.first_name,
    last_name: constituent.last_name,
    date_of_birth: constituent.date_of_birth,
    meta: {
      email: constituent.meta && constituent.meta.email,
      phone: constituent.meta && constituent.meta.phone,
    },
  })
}

export function HouseholdTransformer(household): CreateHouseholdInput {
  return removeEmpty(
    Object.assign(
      {},
      {
        address: CustomerAddressTransformer(household.address),
        adults: household.adults.map((adult) => AdultTransformer(adult)),
      }
    )
  )
}

export function FixAddressRecords(customer) {
  if (customer.adult[0] && customer.adult[0].meta.address) {
    // get the address
    // there's different data structure occouring when address form is shown
    // compared to when no address form is shown
    let address = customer.adult[0].meta.address
    if (customer.adult[0].address && customer.adult[0].address.street1) {
      address = customer.adult[0].address
    } else {
      customer.adult[0].address = address
    }

    // add the address to the adults in the household
    customer.adult[0].meta.address = address
    if (customer.adults[1]) {
      customer.adults[1].meta.address = address
    }
  }
  return customer
}
